<template>
	<div class="page" style='position: relative;'>
		<div class='container' style='padding-top: 10px;'>
			<div class="con">
				<div class="box1">
					<div class="title">请将要扫描的文件 放在高拍仪下</div>
					<img id="view1" src="http://127.0.0.1:38088/video=stream&camidx=0" width="800px" alt="主摄像头">
				</div>
				<div class="box2">
					<!-- <el-slider v-model="size" :min="min" :max="max"  @input="changSize"></el-slider> -->
					<div class="btn" @click="fileScan">
						{{ msg }}
					</div>
					<div class="btn" @click="print">
						生成PDF并下载
					</div>
				</div>
			</div>


			<el-dialog title="提示" :visible.sync="dialogVisible" width="20%" :before-close="handleClose">
				<div class="dialogCon">
					<div class="box">
						<el-radio-group v-model="type">
							<el-radio :label="2">彩色</el-radio>
							<!-- <el-radio :label="1">黑白</el-radio> -->
							<el-radio :label="3">去底色</el-radio>
						</el-radio-group>

                       <div style="line-height: 40px;">
						   价格:{{ price }}
					   </div>
                        
				          
						<div class="btns">
							<el-button type="primary" @click="scanPay">微信扫码支付</el-button>
							<el-button type="primary" @click="balancePayment" v-if="$store.state.userinfo">余额支付
							</el-button>
							
							<el-button type="primary" @click="balancePayment" v-if="$store.state.userinfo">企业支付
							</el-button>
						</div>

						<vue-qr :text="code" :size="200"
							style="margin:0 auto; width:200px; height:200px; margin-top:30px" v-if="iscode"></vue-qr>

					</div>

				</div>

			</el-dialog>


			<el-dialog title="提示" :visible.sync="dialogPic" width="500px">
				<div>
					<img width="450px" :src="`data:imge/jpeg;base64,${pics[currentIndex]}`" alt="">
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="remove">删除</el-button>
					<el-button type="primary" @click="dialogPic = false">关闭</el-button>
				</span>
			</el-dialog>


			<el-dialog :visible.sync="dialogVisible2" width="30%">
				<div class="info" style="text-align: center;">
					<p class='iconfont icon-chenggong' style="font-size: 100px; color:#67C23A"></p>
					<p style="color:#67C23A; font-size: 50px; line-height: 100px;">打印成功</p>
				</div>

			</el-dialog>


			<el-dialog :visible.sync="dialogPdfCode" width="20%" style="text-align: center;">
				<p>请使用微信扫码下载PDF文件</p>
				<vue-qr :text="pdfCode" :size="200" style="margin:0 auto; width:200px; height:200px; margin-top:30px"
					v-if="pdfCode"></vue-qr>
				<p>
					<el-button type="primary" style="width:80%" @click="dialogPdfCode = false">关闭</el-button>
				</p>

			</el-dialog>






			<div class="pics" id="pics">
				<div class="box" v-for="(item,index) in pics" :key="index" @click="show(index)">
					<img :src="`data:imge/jpeg;base64,${item}`" alt="">
				</div>
			</div>
		</div>



		<div class="mask" style="width:100vw; height:100vh; position: absolute; top:-115px;" v-show="loading"
			v-loading="loading" element-loading-text="正在打印" element-loading-spinner="el-icon-loading"
			element-loading-background="rgba(0, 0, 0, 0.5)" customClass="myloading"></div>
	</div>
</template>

<script>
	import {
		getLodop
	} from '../utils/LodopFuncs'

	import vueQr from 'vue-qr'

	import axios from 'axios'
	export default {
		components: {
			vueQr
		},
		data() {
			return {
				pic: require('@/assets/images/code2.jpg'),
				type: 1,
				pics2: [],
				pics: [],
				img: '',
				msg: '拍照',
				dialogVisible: false,
				dialogVisible2: false,
				pdfCode: '',
				dialogPdfCode: false,
				file: '',
				files:{},
				print_ctype: 1,
				dialogPic: false,
				iscode: false,
				jobCode: '1', //job码
				printStatus: 1, //打印状态
				loading: false,
				currentIndex: 0,
				size: 0,
				min: 0,
				max: 9,
				sumMoneyData: {
					type: '',
					page: '',
					number: '',
					ctype: '1',
					paper_type: '1'
				},
				price:'0.1'
			}
		},
		created() {
			//this.kuang()
			 this.common.voice('请将要扫描的文件 放在高拍仪下')
		},

		methods: {
			
			
			
			async sumMoney() {
			
				/* 	try {
				 */
				//this.wxData.print_count = this.pics.length
			
				this.sumMoneyData.number = 1;
				this.sumMoneyData.page = this.pics.length;
				this.sumMoneyData.type = 8;
				this.sumMoneyData.ctype = 1;
				this.sumMoneyData.paper_type = 1;
			
			
			
			
				let res = null;
				if (this.$store.state.userinfo) {
					res = await this.$api.sumMoney(this.sumMoneyData);
				} else {
					res = await this.$api.yksumMoney(this.sumMoneyData);
				}
				this.price = res.data
			//	this.wxData.money_type = res.name;
			
			},
			
			
			
			//文件扫描
			async fileScan() {
				
				
				let data = {
					"filepath": "base64",
					"rotate": "0",
					"cutpage": "0",
					"camidx": "0",
					"ColorMode": "0",
					"quality": "1",
					"notoken": 'true',
					"deskew": "1",
					'currentIndex': 0,
					'dialogVisible2': false
				}



				let {
					data: res
				} = await axios.post("http://127.0.0.1:38088/video=grabimage", JSON.stringify(data));

				data.ColorMode = "4"; // 去底色
				let {
					data: res2
				} = await axios.post("http://127.0.0.1:38088/video=grabimage", JSON.stringify(data));


				//return false;
				this.pics.push(res.photoBase64);
				this.pics2.push(res2.photoBase64);

				console.log('pics', this.pics);

			},

			handleClose() {
				this.dialogVisible = false;
				this.iscode = false;
				clearTimeout(this.timer)
			},

			async changSize(e) {
				console.log(e)
				let data = {
					"camidx": "0", // 摄像头，0:主头；1:副头
					"corp": e + '' // 放大比例，取值0~9，0：原图，9最大显示  
				}

				let res = await axios.post("http://127.0.0.1:38088/stream=zoominout", JSON.stringify(data));
				console.log('大小', res)
			},

			async setSize(e) {
				console.log(e)
				let data = {
					"camidx": "0", // 摄像头，0:主头；1:副头
					"corp": e + '' // 放大比例，取值0~9，0：原图，9最大显示  
				}

				let res = await axios.post("http://127.0.0.1:38088/stream=zoominout", JSON.stringify(data));
				console.log('大小', res)
			},



			show(index) {
				console.log(index)
				this.currentIndex = index;
				this.dialogPic = true;
			},


			//删除图片
			remove() {

				this.$confirm('您确认删除此照片', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.pics.splice(this.currentIndex, 1)
					this.pics2.splice(this.currentIndex, 1)
					this.dialogPic = false;
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});


			},


			//扫码支付
			scanPay() {
				
				if(this.type == 1){
					 this.file = this.files.data_hb;
				}
				
				if(this.type == 2){
					 this.file = this.files.data;
				}
				
				if(this.type == 3){
					 this.file = this.files.file_hb_qds;
				}
				let data4 = {
					ctype: "2",
					money_type: "SCAN",
					paper_type: this.type,
					print_count: this.pics.length,
					print_ctype: 1,
					print_file: this.file,
					print_number: 1,
					print_type: 2,
					task_type: 8,
					type: 2 //打印
				}

				if (this.$store.state.userinfo) {
					this.$api.scanPay(data4).then(res => {
						console.log('res', res)
						this.code = res.data.code_url;
						this.iscode = true;
						console.log('code11', this.code)

						this.timer = setInterval(() => {
							this.scanPaySuccessful(res.data.order_number);
						}, 1000)


					});

				} else {
					this.$api.ykscanPay(data4).then(res => {
						console.log('res', res)
						this.code = res.data.code_url;
						this.iscode = true;
						console.log('code11', this.code)

						this.timer = setInterval(() => {
							this.ykscanPaySuccessful(res.data.order_number);
						}, 1000)


					});

				}



			},

			async scanPaySuccessful(order) {
              


				let res = await this.$api.scanPaySuccessful({
					'order_number': order
				});


				console.log(res);
				if (res.data == "SUCCESS") {
					clearTimeout(this.timer);
					this.dialogVisible = false;
					this.$message.success('扫码支付成功，请扫二维码下载pdf文件')
					setTimeout(() => {
						
			
						
						this.pdfCode = this.$domain + '/' + this.file;
						this.dialogPdfCode = true;
					}, 1500)


					clearTimeout(this.timer)
				}
			},


			async ykscanPaySuccessful(order) {


				let res = await this.$api.ykscanPaySuccessful({
					'order_number': order
				});


				console.log(res);
				if (res.data == "SUCCESS") {
					clearTimeout(this.timer);
					this.dialogVisible = false;
					this.$message.success('扫码支付成功，请扫二维码下载pdf文件')
					setTimeout(() => {
						
						this.pdfCode = this.$domain + '/' + this.file
						this.dialogPdfCode = true;
					}, 1500)


					clearTimeout(this.timer)
				}
			},




			async print() {
				console.log(222)

                  this.sumMoney();

				const loading = this.$loading({
					lock: true,
					text: '文件处理中',
					color: "#fff",
					fontSize: 100,
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});



				let data3 = this.pics;
				let res3 = await this.$api.base64Pdf({
					'color': this.pics,
					'nocolor': this.pics2
				});
				this.dialogVisible = true;

				
				
				
				this.files = res3;
				


				loading.close()

				if (this.$store.state.userinfo) {
					let param = {
						name: Date.parse(new Date()) + '.pdf',
						url: res3.data,
						file_hb_url: res3.data_hb,
						file_hb_qds_url: res3.file_hb_qds,
						print_type: 7
					}
					this.$api.fileSave(param)
				}

				this.dialogVisible = true;

			},


			//余额支付
			balancePayment() {
				if(this.type == 1){
					 this.file = this.files.data_hb;
				}
				
				if(this.type == 2){
					 this.file = this.files.data;
				}
				
				if(this.type == 3){
					 this.file = this.files.file_hb_qds;
				}
				
				let data4 = {
					ctype: "2",
					money_type: "SCAN",
					paper_type: this.type,
					print_count: 1,
					print_ctype: this.print_ctype,
					print_file: this.file,
					print_number: 1,
					print_type: 2,
					task_type: 7,
					type: 1
				}
				console.log('aaa', data4)

				this.$api.balancePayment(data4).then(res => {
					console.log('res', res)
					if (res.code == 200) {
						this.$message.success('余额支付成功,请扫码下载');
						setTimeout(() => {
							this.dialogVisible = false;
							
							this.pdfCode = this.$domain + '/' + this.file;
							this.dialogPdfCode = true;
						}, 1500)

					}



					/* 	let data = {
							'print_file': this.file,
							'print_ctype': this.print_ctype,
							'task_number': res.task_number
						}
						this.printFile(data); */
				});


			},




			printFile(obj) {

				let LODOP = getLodop();
				this.dialogVisible = false;
				this.loading = true;
				let self = this;

				LODOP.PRINT_INIT("测试PDF打印功能");
				LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['EPSON WF-C5290 Series']);

				console.log(2233, this.demoDownloadPDF(obj.print_file))

				LODOP.ADD_PRINT_PDF(0, 0, "100%", "100%", this.demoDownloadPDF(obj.print_file));
				if (obj.print_ctype == 2) { //双面打印
					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 2);
				}

				LODOP.SET_PRINT_COPIES(1); //打印份数
				//LODOP.SET_PRINT_STYLE('FontColor','#000')
				LODOP.SET_PRINT_STYLEA(0, "FontColor", "#808080");

				LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);

				LODOP.On_Return = function(TaskID, Value) {
					console.log("TaskID:" + TaskID);
					console.log("Value:" + Value); //job代码
					self.jobCode = Value;
					var timer = setInterval(function() {
						console.log("每次轮询的状态aaa：" + self.printStatus);
						if (self.printStatus != 1) { //打印成功
							clearInterval(timer);
							self.printStatus = 1;
							self.loading = false;
							self.open();
							return;
						}
						self.getStatusValue(Value);
					}, 1000);
				};
				//LODOP.PRINT();
				//	LODOP.PRINT();
				LODOP.PRINT();
				let data = {
					'task_number': obj.task_number,
					'device_number': localStorage.getItem('device')
				};
				this.accomplish(data);

				return;


			},

			async accomplish(data) {

				let res = await this.$api.accomplish(data);
				console.log(111);
				//	this.loading = false;
				//this.TaskList();
			},

			open() {
				this.dialogVisible2 = true;
				setTimeout(() => {
					this.dialogVisible2 = false;
				}, 5000)
			},


			getStatusValue(job) { //根据job代码，获取是否打印成功

				var self = this;
				LODOP = getLodop();
				LODOP.On_Return = function(TaskID, Value) {
					console.log("TaskID:" + TaskID);
					console.log("打印成功状态:" + Value); //打印成功状态
					self.printStatus = Value;
				};
				LODOP.GET_VALUE("PRINT_STATUS_EXIST", job);
			},



			demoDownloadPDF(url) {
				if (!(/^https?:/i.test(url))) return;
				if (window.XMLHttpRequest) var xhr = new XMLHttpRequest();
				else var xhr = new ActiveXObject("MSXML2.XMLHTTP");
				xhr.open('GET', url, false); //同步方式
				if (xhr.overrideMimeType)
					try {
						xhr.responseType = 'arraybuffer';
						var arrybuffer = true;
					} catch (err) {
						xhr.overrideMimeType('text/plain; charset=x-user-defined');
					}
				xhr.send(null);
				var data = xhr.response || xhr.responseBody;
				if (typeof Uint8Array !== 'undefined') {
					if (arrybuffer) var dataArray = new Uint8Array(data);
					else {
						var dataArray = new Uint8Array(data.length);
						for (var i = 0; i < dataArray.length; i++) {
							dataArray[i] = data.charCodeAt(i);
						}
					}
				} else
					var dataArray = VBS_BinaryToArray(data).toArray(); //兼容IE低版本
				return this.demoGetBASE64(dataArray);
			},

			demoGetBASE64(dataArray) {
				var digits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
				var strData = "";
				for (var i = 0, ii = dataArray.length; i < ii; i += 3) {
					if (isNaN(dataArray[i])) break;
					var b1 = dataArray[i] & 0xFF,
						b2 = dataArray[i + 1] & 0xFF,
						b3 = dataArray[i + 2] & 0xFF;
					var d1 = b1 >> 2,
						d2 = ((b1 & 3) << 4) | (b2 >> 4);
					var d3 = i + 1 < ii ? ((b2 & 0xF) << 2) | (b3 >> 6) : 64;
					var d4 = i + 2 < ii ? (b3 & 0x3F) : 64;
					strData += digits.substring(d1, d1 + 1) + digits.substring(d2, d2 + 1) + digits.substring(d3, d3 + 1) +
						digits.substring(d4, d4 + 1);
				}
				return strData;
			},






			base64ImgtoFile(dataurl, filename = 'file') {
				let arr = dataurl.split(',')
				let mime = arr[0].match(/:(.*?);/)[1]
				let suffix = mime.split('/')[1]
				let bstr = atob(arr[1])
				let n = bstr.length
				let u8arr = new Uint8Array(n)
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n)
				}
				return new File([u8arr], `${filename}.${suffix}`, {
					type: mime
				})
			},





			kuang() {
				let data = {
					"camidx": "0", // 摄像头索引，0：主头；1：副头
					"open": "1" // 状态，0：关；1：开
				};
				axios.post("http://127.0.0.1:38088/dvideo=cameradeskew", JSON.stringify(data)).then((res) => {
					console.log(res)
				})
			}
		}


	}
</script>

<style scoped lang='scss'>
	.con {
		display: flex;
		justify-content: space-between;
		height: 500px;
		margin-top: 10px;
		box-shadow: 1px 1px 10px #ccc inset;
		margin-top: 15px;

		.title {
			font-size: 30px;
			text-align: center;
			margin-top: 10px;
			color: #FFF566;
		}

		.box1 {
			flex: 2;

			img {
				display: block;
				margin: 20px auto 0px;
				width: 400px;
				height: 300px;
			}
		}


		.box2 {
			flex: 1;

			.btn {
				width: 70%;
				height: 60px;
				line-height: 60px;
				background-color: #95DE64;
				text-align: center;
				margin: 0 auto;
				margin-top: 100px;
				font-size: 30px;
				color: #fff;
				cursor: pointer;

			}
		}

	}

	.pics {

		height: 150px;
		box-shadow: 1px 1px 10px #ccc inset;
		margin-top: 15px;
		overflow-x: auto;

		img {
			display: block;
			float: left;
			height: 130px;
			width: 100px;
			margin: 10px;
			/* 	background-color: rgba(0,0,0,0.5); */

		}
	}

	.dialogCon {
		height: 350px;

		text-align: center;

		img {
			width: 200px;
			margin: 0 auto;
			display: block;

		}

		.btns {
			margin-top: 20px;
		}

	}
</style>


<style>
	.el-loading-spinner .el-loading-text {
		color: #fff !important;
		font-size: 30px !important;
	}

	.el-loading-spinner .el-icon-loading {
		color: #fff !important;
		font-size: 100px !important;
	}
</style>
